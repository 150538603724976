import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutStyles from "../styles/pages/about.module.css"

import aboutDescContent from "../images/about-desc-content.png"

const AboutPage = () => (
    <Layout location="about">
        <SEO title="About" />
        <div className={ aboutStyles.header }>
            <div className={ aboutStyles.headerText }>
            <h4 className={ aboutStyles.title }>About //</h4>
            <p className={ aboutStyles.subtitle }>Learn more about the company that delivers.</p>
            </div>
        </div>
        <div className={ aboutStyles.description }>
            <div className={ aboutStyles.descriptionContent }>
                <img src={ aboutDescContent } alt=""/>
            </div>
            <div className={ aboutStyles.descriptionText }>
                <p>Flair! Talent Bureau is your source for talent. Drawing from a brimming pool of fresh or established, promising or proven models, artists, speakers, performers, and other unique talents. Flair! thrives on its ability to provide who and what you need for your events, commercial shoots, movies, and prints ad commitments.</p>
                <p>We specifically cater to your needs when it comes to meeting the demand for having quality, distinctly gifted individuals and groups as talents. Drawing from a dynamic pool of only the most promising models, artists, speakers, and performers in the country.</p>
            </div>
        </div>
    </Layout>
)

export default AboutPage